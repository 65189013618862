import { render, staticRenderFns } from "./channel-business-info.vue?vue&type=template&id=6ed4864e&scoped=true&"
import script from "./channel-business-info.vue?vue&type=script&lang=js&"
export * from "./channel-business-info.vue?vue&type=script&lang=js&"
import style0 from "../../assets/css/info-page.css?vue&type=style&index=0&id=6ed4864e&scoped=true&lang=css&"
import style1 from "./channel-business-info.vue?vue&type=style&index=1&id=6ed4864e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ed4864e",
  null
  
)

export default component.exports