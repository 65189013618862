<template>
  <!-- 渠道商 -->
  <div class="container">
    <a-page-header :title="title" @back="() => $router.go(-1)">
      <template slot="extra">
        <a-button @click="onPreviewQRcode">
          分享二维码（渠道）
        </a-button>
        <a-button @click="jump">
          渠道开发的终端
        </a-button>
      </template>
    </a-page-header>
    <div class="main-content">
      <div class="text-content" v-if="info">
        <div class="content-item">
          <div class="content-item-header">
            <div class="title">渠道商信息</div>
          </div>
          <div class="content-sub-title">概述</div>

          <div class="ul">
            <div class="li">
              <span class="label">渠道商名称：</span>
              <span>{{ info.realName }}</span>
            </div>
            <div class="li">
              <span class="label">渠道开发的终端数：</span>
              <span>{{ info.num }}</span>
            </div>
            <div class="li">
              <span class="label">渠道商业绩比例：</span>
              <span>{{ info.rate }}%</span>
            </div>
          </div>
        </div>

        <div class="content-item">
          <div class="content-item-header">
            <div class="title">账户信息</div>
          </div>
          <div class="content-sub-title">微信账户</div>

          <div class="ul">
            <div class="li">
              <span class="label">微信昵称：</span>
              <span>{{ info.nickname }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <a-modal
      title="分享二维码"
      :visible="modalQrcodeVisible"
      @cancel="onPreviewQRcodeClose"
      :footer="false"
    >
      <img class="qrcode" :src="qrcode" />
    </a-modal>
  </div>
</template>

<script>
import NP from 'number-precision'
export default {
  data() {
    return {
      info: null,
      modalQrcodeVisible: false,
      qrcode: ''
    }
  },
  created() {
    this.title = this.$getPageTitle()
    const id = this.$route.query.id
    this.getChannelBusinessInfo(id)
  },
  methods: {
    getChannelBusinessInfo(id) {
      const data = {
        id
      }
      this.$axios.getChannelBusinessInfo(data).then((res) => {
        const d = res.data.data
        d.rate = NP.times(d.rate, 100)
        this.info = d
      })
    },
    onPreviewQRcode() {
      // 预览二维码
      this.qrcode = this.info.wechatAppletsQcode
      this.modalQrcodeVisible = true
    },
    onPreviewQRcodeClose() {
      // 关闭预览二维码
      this.qrcode = ''
      this.modalQrcodeVisible = false
    },
    jump() {
      // 跳转渠道终端
      this.$router.push({
        path: '/channelTerminal',
        query: {
          id: this.info.id
        }
      })
    }
  }
}
</script>

<style src="../../assets/css/info-page.css" scoped />
<style scoped>
.qrcode {
  display: block;
  margin: 0 auto;
}
</style>
